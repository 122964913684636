<template lang="pug">
include ../../../configs/template.pug
div.row.mt-6
  div.col-sm-12.col-md-6
    +field-validation('body.other_number', 'number', '["required"]')
  div.col-sm-12.col-md-6
    +date-picker-validation('body.date_start', 'dateIssue', 'dateStartObject', '["required", "minValue", "maxValue"]')(
      :max="new Date().toISOString()" min="1900-01-01")
  div.col-sm-12.col-md-6
    +select-validation('body.issuing_authority', 'listIssuingAuthority', 'passportIssued', 'nameLang', '["required"]')
  div.col-sm-12.col-md-6
    +field-validation('body.strict_blank', 'strictBlank', '["required"]')
  div.col-sm-12.col-md-6
    +field-validation('body.authorized_official_ukr', '', '["required", "alphaUA"]')(
      :label="`${$t('agent')} - ${$t('nameUK')}`")
  div.col-sm-12.col-md-6
    +field-validation('body.authorized_official_eng', '', '["required", "alphaEN"]')(
      :label="`${$t('agent')} - ${$t('nameEN')}`")
  div.col-sm-12
    +select-validation('body.type', 'navigatorCertificateTypes', 'typeDoc', 'nameLang', '["required"]')(item-value="value")
  div(v-if="body.type === 'ukrainian'").col-sm-12.col-md-6
    +select-validation('body.position', 'listPositions', 'position', 'nameLang', '["required"]')(
      @input="body.ship_group = null")
  div(v-if="body.type === 'ukrainian'").col-sm-12.col-md-6
    +select-validation('body.ship_group', 'listShipGroups', 'shipGroup', 'nameLang', '["required"]')
  div(v-if="body.type === 'european'").col-sm-12.text-left
    v-radio-group(
      v-model="body.category"
      :label="$t('category')"
      :error-messages="$valid.validation($v.body.category, ['required'])"
      row).mt-0
      v-radio(
        v-for="category in navigatorCertificateCategories"
        :key="category"
        :value="category"
        :label="category")
  div(v-if="body.type === 'european'").col-sm-12.col-md-6
    +select-validation('body.radio_equipment', 'listRadioEquipment', 'shipEquipment', 'nameLang', '["required"]')(multiple)
  div(v-if="body.type === 'european'").col-sm-12.col-md-6
    +date-picker-validation('body.date_end', 'dateTermination', 'dateEndObject', '["required", "minValue", "maxValue"]')(
      max="2200-12-31" :min="body.date_start || '1900-01-01'")
  div(v-if="body.type === 'european'").col-sm-12.col-md-4
    +select('body.rivers.river', 'listRivers', 'river', 'nameLang')
  div(v-if="body.type === 'european'").col-sm-12.col-md-4
    +field-validation('body.rivers.start_area', 'sectionFrom', '["required", "minValue", "maxValue"]')(
      v-mask="'####'" type="number")
  div(v-if="body.type === 'european'").col-sm-12.col-md-4
    +field-validation('body.rivers.end_area', 'sectionTo', '["required", "minValue", "maxValue"]')(
      v-mask="'####'" type="number")
  div(v-if="checkAccess('navigatorCertificates', 'add_view')").col-sm-12
    FileDropZone(ref="mediaContent")
  div.col-sm-12.d-flex.justify-center.mt-3
    v-btn(:loading="isLoading" color="success" @click="checkFieldsValidation") {{ $t('save') }}
</template>

<script>
import FileDropZone from '@/components/atoms/DropZone/DropZone.vue'
import { mapState, mapGetters, mapActions } from 'vuex'
import { clearBody } from '@/mixins/main'
import { checkAccess } from '@/mixins/permissions'
import { SUCCESS_CODE } from '@/configs/constants'
import { sailor } from '@/mixins/validationRules'

const initBody = () => ({
  type: 'ukrainian',
  other_number: null,
  date_start: null,
  issuing_authority: null,
  strict_blank: null,
  authorized_official_ukr: null,
  authorized_official_eng: null,
  position: null,
  ship_group: null,
  date_end: null,
  rivers: {
    river: 1,
    start_area: null,
    end_area: null
  },
  category: null,
  radio_equipment: []
}
)

export default {
  name: 'SailorNavigatorCertificateAdd',
  props: {
    statement: { type: Object, default: () => ({}) }
  },
  components: {
    FileDropZone
  },
  data () {
    return {
      body: initBody(),
      isLoading: false,
      checkAccess
    }
  },
  computed: {
    ...mapGetters(['positionsByRankID', 'getDirectoryObject', 'positionByID']),
    ...mapState({
      nameLang: state => (state.main.lang === 'en') ? 'name_eng' : 'name_ukr',
      // List documents
      listIssuingAuthority: state => state.directory.issuingAuthority,
      listRadioEquipment: state => state.directory.radioEquipment,
      listRivers: state => state.directory.rivers,
      navigatorCertificateTypes: state => state.directory.navigatorCertificateTypes,
      navigatorCertificateCategories: state => state.directory.navigatorCertificateCategories
    }),
    listPositions () {
      return this.positionsByRankID(162)
    },
    listShipGroups () {
      if (!this.body.position) return []

      const GROUPS = []
      this.positionByID(this.body.position).ship_groups.forEach(group => {
        GROUPS.push(this.getDirectoryObject({ id: group, value: 'shipGroups' }))
      })
      return GROUPS
    },
    dateStartObject () {
      return this.body.date_start ? new Date(this.body.date_start) : null
    },
    dateEndObject () {
      return this.body.date_end ? new Date(this.body.date_end) : null
    }
  },
  validations () { return sailor.qualification.cerificateOfNavigation(this) },
  mounted () {
    if (this.$route.params?.documentID) {
      Object.keys(this.body).forEach(key => {
        if (key === 'rivers') {
          this.statement[key].forEach((el) => {
            Object.entries(el).forEach(([key2, value]) => {
              this.body[key][key2] = value
            })
          })
        } else this.body[key] = this.statement[key]
      })
    }
  },
  methods: {
    ...mapActions(['setNavigatorCertificates', 'updateNavigatorCertificates']),
    checkFieldsValidation () { // Check fields validation
      if (this.$v.$invalid) this.$v.$touch()
      else this.saveNavigatorCertificate()
    },

    async saveNavigatorCertificate () { // Add new navigator certificate
      let data = {
        ...this.$route.params,
        body: { ...this.body },
        media: {
          files: [...this.$refs.mediaContent.filesArray],
          file_type: 'certificate_of_navigator'
        }
      }
      if (this.body.type === 'european') {
        data.body.date_end = this.body.date_end
        data.body.radio_equipment = this.body.radio_equipment
        data.body.category = this.body.category
        let RIVERS = []
        RIVERS.push(this.body.rivers)
        data.body.rivers = RIVERS
      } else {
        delete data.body.rivers
        data.body.position = this.body.position
        data.body.ship_group = this.body.ship_group
      }
      data.body = { ...clearBody({ ...data.body }) }
      this.isLoading = true
      const response = await this[`${this.$route.params?.documentID ? 'update' : 'set'}NavigatorCertificates`](data)
      if (SUCCESS_CODE.includes(response.code)) {
        if (this.$route.params?.documentID) this.$notification.success('editedShipownerLicense')
        else this.$notification.success('addedShipownerLicense')
        this.$data.body = initBody()
        this.$parent.isViewAddSlot = false
        this.$v.$reset()
      }
      this.isLoading = false
    }
  }
}

</script>
